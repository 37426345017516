<template>
    <div class="header-advantages text-yanone text-bold text-white">
        <div class="header-advantages-items" :style="{ transform: `translateY(-${translation}%)` }">
            <div
                v-for="item in items"
                class="header-advantages-item d-flex flex-justify-center flex-align-center"
            >
                <i class="icon-cr white mr-2" :class="item.icon"></i
                >{{ isMobile ? item.text_mobile : item.text_desktop }}
            </div>
        </div>
    </div>
</template>

<script>
    import { resizeOrientationAware } from '../../../common/common';

    export default {
        name: 'HeaderAdvantagesSlider',

        data() {
            const items = [
                {
                    icon: 'icon-carrier',
                    text_desktop: `Frete grátis a partir de R$155 (consulte as condições na sacola de compras)`,
                    text_mobile: `Frete grátis a partir de R$155*`,
                },
                {
                    icon: 'icon-cashback',
                    text_desktop: `Seu pedido gera Cashback!`,
                    text_mobile: `Seu pedido gera Cashback!`,
                },
                {
                    icon: 'icon-exchange',
                    text_desktop: 'Primeira troca grátis',
                    text_mobile: 'Primeira troca grátis',
                },
                {
                    icon: 'icon-credit-card',
                    text_desktop: 'Pague no cartão em até 4x sem juros',
                    text_mobile: 'Pagamento em até 4x sem juros',
                },
            ];

            return { items, direction: 1, translation: 0, isMobile: null };
        },

        mounted() {
            this.resizeAwareRemoveCb = resizeOrientationAware(() => this.onResize(), true);
            setInterval(this.translate, 3000);
        },

        beforeDestroy() {
            this.resizeAwareRemoveCb();
        },

        watch: {
            translation(to) {
                if (to === 0 || to === (this.items.length - 1) * 100) {
                    this.direction *= -1;
                }
            },
        },

        methods: {
            translate() {
                this.translation += 100 * this.direction;
            },

            onResize() {
                this.isMobile = window.innerWidth < 768;
            },
        },
    };
</script>
