import './common/base';
import './vue/top-advantages';
import { initHoverAccordions } from './common/cr-components';
import { getScope, setCookie, readCookie, EventBus } from './common/common';
import Recommendations from './vue/components/common/Recommendations';
import Vue from './vue/common/vue-common';
import ProductList from './vue/components/common/ProductList';
import WholesalerHighlights from './vue/components/home/HomeWholesalerHighlights';
import Lazy from './vue/components/common/Lazy';
import RenderNotifier from './vue/components/common/RenderNotifier';
import WholesalerCollections from './vue/components/home/HomeWholesalerCollections';
import HorizontalScrollbar from './vue/components/common/container/HorizontalScrollbar';
import { getSimpleVueHorizontalScroll } from './vue/horizontal-scroll';
import locale from './vue/i18n/locale';
import general from './vue/i18n/general';
import ShippingRules from './vue/components/product/ShippingRules.vue';
import HomeCountdownAlternate2 from './vue/components/home/HomeCountdownAlternate2.vue';

let isRegularCustomer = getScope('is_regular_customer');
let isMobile = getScope('mobile_version');

cr$.ready(function () {
    // PWA
    let deferredPrompt;
    const a2hsBanner = cr$.byId('a2hsBanner');

    let doNotShowA2HS = function () {
        a2hsBanner.css('display', 'none');
        let d = new Date();
        d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
        setCookie('_chPWA', '1', d.toUTCString(), '/'); //Limita 1x por mês
        deferredPrompt = null;
    };

    window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault(); // Prevent Chrome 67 and earlier from automatically showing the prompt
        deferredPrompt = e; // Stash the event so it can be triggered later.
        deferredPrompt.userChoice.then((choiceResult) => {
            // Wait for the user to respond to the prompt
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the A2HS prompt');
            } else {
                console.log('User dismissed the A2HS prompt');
            }

            doNotShowA2HS();
        });

        if (!readCookie('_chPWA')) {
            a2hsBanner.css('display', 'block');
        }
    });

    window.addEventListener('appinstalled', doNotShowA2HS);

    cr$.byId('a2hsAccept').click(() => {
        if (deferredPrompt) {
            deferredPrompt.prompt(); // Show the prompt
        }
    });

    cr$.byId('a2hsDeny').click(doNotShowA2HS);

    // End PWA

    const highlightsEl = cr$.byId('home-week-highlights-vue');
    if (highlightsEl.exists()) {
        requestIdleCallback(() => {
            new Vue({
                el: highlightsEl.raw,
                i18n: locale(general()),
                template: `
                    <RenderNotifier>
                    <ProductList
                        id="home-week-highlights"
                        :products="products"
                        :shorter-image="false"
                        list-class="product-horizontal-list product-horizontal-list-4 no-scrollbar"
                        event-name="Sucessos da última semana home"
                        :item-options="{displayPercent: true, hidePromoBadge:true, hidePriceOld: true}"
                        @scroll="scrollEvent = $event"/>
                    <HorizontalScrollbar
                        :scroll-event="scrollEvent"
                        :button-height="300"
                        :id-list="'home-week-highlights'"
                    />
                    </RenderNotifier>`,
                components: {
                    ProductList,
                    RenderNotifier,
                    HorizontalScrollbar,
                },
                data() {
                    return {
                        scrollEvent: null,
                        products: getScope('HOME').FEATURED_ITEMS,
                    };
                },
            });
        });
    }

    const categoriesEl = cr$.byId('home-categories-vue');
    if (categoriesEl.exists()) {
        requestIdleCallback(() => getSimpleVueHorizontalScroll(categoriesEl.raw));
    }

    const miniBannersEl = cr$.byId('home-collections-mini-banners-vue');
    if (highlightsEl.exists()) {
        requestIdleCallback(() => getSimpleVueHorizontalScroll(miniBannersEl.raw, true));
    }

    const otherProductsEl = cr$.byId('home-other-products-vue');
    if (otherProductsEl.exists()) {
        requestIdleCallback(() => getSimpleVueHorizontalScroll(otherProductsEl.raw, true));
    }

    if (!isMobile) {
        requestIdleCallback(() => initHoverAccordions());
    }

    cr$.byId('toggle-bh').click(function () {
        cr$.byId('bh-info').toggle();
    });
    cr$.byId('toggle-jf').click(function () {
        cr$.byId('jf-info').toggle();
    });
    cr$.byId('toggle-tiradentes').click(function () {
        cr$.byId('tr-info').toggle();
    });

    cr$.queryAll('.product-list-item a').click(function () {
        const elm = cr$.byEl(this);
        window.dataLayer.push({
            event: 'productClick',
            ecommerce: {
                click: {
                    actionField: { list: elm.attr('data-list') },
                    products: [
                        {
                            name: elm.attr('data-name'),
                            id: elm.attr('data-id'),
                            price: elm.attr('data-price'),
                            brand: 'Chico Rei',
                            category: elm.attr('data-category'),
                            variant: '',
                            position: elm.attr('data-position'),
                        },
                    ],
                },
            },
        });
    });

    cr$.byId('fast-delivery-home-click').click(function () {
        EventBus.$emit('fast-delivery-edit', true);
    });

    if (!isRegularCustomer) {
        new Vue({
            el: '#products-wholesalers-vue',
            i18n: locale(general()),
            template: `
                <Lazy>
                <WholesalerHighlights
                    :stylize-title="false"
                    title="Últimas camisetas lançadas"
                    :quantity="6"
                    type="wholesaler"/>
                </Lazy>`,
            components: { Lazy, WholesalerHighlights },
        });

        new Vue({
            el: '#home-collections-vue',
            i18n: locale(general()),
            template: `
                <Lazy>
                <WholesalerCollections/>
                </Lazy>`,
            components: { Lazy, WholesalerCollections },
        });
    } else {
        requestIdleCallback(() => {
            new Vue({
                el: '#recommendations-vue',
                i18n: locale(general()),
                template: `
                    <Lazy>
                    <Recommendations
                        :params="params"
                        list-event-name="Recomendações home"
                        :item-options="{hidePromoBadge: true, hidePriceOld: true}"
                        :shorter-image="false"
                        list-class="product-horizontal-list product-horizontal-list-4 no-scrollbar"
                    />
                    </Lazy>`,
                components: { Lazy, Recommendations },
                data() {
                    return {
                        success: false,
                        params: {
                            scenario: 'home',
                            rotationRate: 0,
                            count: 6,
                            minRelevance: 'medium',
                        },
                    };
                },
            });
        });

        // requestIdleCallback(() => {
        //     new Vue(HomeNewsletter);
        // });

        requestIdleCallback(() => {
            new Vue({
                el: '#home-shipping-rules-vue',
                template: `
                <ShippingRules v-model="showShippingRules" :listen-event="true"/> `,
                components: { ShippingRules },
                data() {
                    return {
                        showShippingRules: false,
                    };
                },
            });
        });

        // requestIdleCallback(() => {
        //     new Vue(HomeNewsletter);
        // });

        const alternate2CountdownEl = cr$.byId('vue-home-countdown-alternate-2');
        if (alternate2CountdownEl.exists()) {
            requestIdleCallback(() => {
                new Vue(HomeCountdownAlternate2);
            });
        }
    }
});
